import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Button from "../../components/buttons/button";
import FormFields from "../../components/formFields/formField";
import Banner from "../../imgs/bg_contacto.jpg"
import BannerMobile from "../../imgs/bg_nordelta_mobile.jpg";
import ProductBanner from "../../components/product_banners/product_banners";
/*import GoogleMap from "../components/google_map/google_map";*/
import { apiRequest } from "../../apiRquest/apiRequest";
import "./nordelta.css";
/*import "./pages.css";*/
import PhoneIcon from "../../imgs/phone-icon.svg";
import EnvelopeIcon from "../../imgs/envelope-icon.svg";
import RedesIcon from "../../imgs/redes-grupo-x.svg";
import { SalWrapper } from "react-sal";
import validator from "validator";
import {
  ValidateInputEmail,
  validateInputNumeric,
  validateInputPhone,
  validateInputPhoneNumber,
  validateInputText,
} from "../../helpers/validates";
import { number } from "prop-types";
import {getUtmParamsString} from "../../helpers/commoms";
const pageTitle = "Abrí tu cuenta especial CERA I Balanz";
const pageDesc =
  "Dejanos tus datos y abrí tu cuenta CERA. Te acompañamos durante todo el proceso para invertir tu blanqueo.";
// Step 2: Define your component
const ContactPage = () => {
  const [name, setName] = useState("");
  const [errorinputName, setErrorInputName] = useState(false);
  const [errorinputNameMessage, setErrorInputNameMessage] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorinputLastName, setErrorInputLastName] = useState(false);
  const [errorinputLastNameMessage, setErrorInputLastNameMessage] = useState("");
  const [email, setEmail] = useState("");
  const [errorinputEmail, setErrorInputEmail] = useState(false);
  const [errorinputEmailMessage, setErrorInputEmailMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [errorinputPhone, setErrorInputPhone] = useState(false);
  const [errorinputPhoneMessage, setErrorInputPhoneMessage] = useState("");
  const [message, setMessage] = useState("");
  const [errorInputNote, setErrorInputNote] = useState(false);
  const [errorinputNoteMessage, setErrorInputNoteMessage] = useState("");
  const [checked, setChecked] = useState(false);
  const [ifclientBalanz, setIfClientBalanz] = useState("");
  const [errorinputChecked, setErrorInputChecked] = useState(false);
  const [errorinputRadio, setErrorInputRadio] = useState(false);
  const [errorinputCheckedMessage, setErrorInputCheckedMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [statusMsg, setStatusMsg] = useState("");
  const [successResponse, setSuccessResponse] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [sendBtnEnable, setSendBtnEnable] = React.useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [bamCampaign, setBamCampaign] = useState(706);
  const [utmList, setUtmList] = useState('');
  const validateForm = (formFields) => {
    const { email_personal, last_names, first_name, note, phone, newsletter, cliente_balanz } =
      formFields;

    let error = 0;
    if (first_name.length === 0) {
      setErrorInputName(true);
      setErrorInputNameMessage("Campo requerido");
      error++;
    } else if (first_name.length > 3 && !validateInputText(first_name)) {
      setErrorInputName(true);
      setErrorInputNameMessage("Nombre solo debe contener letras");
      error++;
    } else {
      setErrorInputName(false);
    }

    if (last_names.length === 0) {
      setErrorInputLastName(true);
      setErrorInputLastNameMessage("Campo requerido");
      error++;
    } else if (last_names.length > 3 && !validateInputText(last_names)) {
      setErrorInputLastName(true);
      setErrorInputLastNameMessage("Apellido solo debe contener letras");
      error++;
    } else {
      setErrorInputLastName(false);
    }

    if (email_personal.length < 5) {
      setErrorInputEmail(true);
      setErrorInputEmailMessage("Campo requerido");
      error++;
    } else if (!ValidateInputEmail(email_personal)) {
      setErrorInputEmail(true);
      setErrorInputEmailMessage("Email inválido");
      error++;
    } else {
      setErrorInputEmail(false);
    }
    if (note.length === 0) {
      setErrorInputNoteMessage("Campo requerido");
      setErrorInputNote(true);
      error++;
    } else {
      setErrorInputNote(false);
    }

    if (phone.length === 0) {
      setErrorInputPhone(true);
      setErrorInputPhoneMessage("Campo requerido");
      error++;
    } else if (!validateInputNumeric(phone)) {
      setErrorInputPhone(true);
      setErrorInputPhoneMessage("Teléfono solo admite números (0-9)");
      error++;
    } else if (phone.length <= 8 || !validateInputNumeric(phone)) {
      setErrorInputPhone(true);
      setErrorInputPhoneMessage("Teléfono solo admite números (0-9)");
      error++;
    } else {
      setErrorInputPhone(false);
    }
    if (newsletter === "false") {
      setErrorInputChecked(true);
      error++;
    } else {
      setErrorInputChecked(false);
    }
    if (cliente_balanz === "") {
      setErrorInputRadio(true);
      error++;
    } else {
      setErrorInputRadio(false);
    }
    return error;
  };
  const submit = async () => {
    const formFields = {
      email_personal: email,
      last_names: lastName,
      first_name: name,
      note: message,
      phone: phone,
      newsletter: checked ? "true" : "false",
      cliente_balanz: ifclientBalanz,
      idCampaignAction: bamCampaign,
      leadUtmString: utmList
    };

    if (!validateForm(formFields)) {
      setSendBtnEnable(false);
      await sendDataForm(formFields);
      setErrorMessage("");
    } else {
      setErrorMessage("Por favor complete los campos obligatorios");
    }
  };
  const sendDataForm = async (formFields) => {
    try {
      apiRequest
        .post("/v1/contacts/blanqueo_capitales", formFields)
        .then(() => {
          //alert("gracias por suscribirte!!")
          setSuccessResponse(true);
          setStatusMsg(
            "Los datos se han enviado correctamente.<br />A la brevedad nos pondremos en contacto."
          );
          setModalShow(true);
          clearFields();
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "contact_form",
          });
          setSendBtnEnable(true);
        })
        .catch((err) => {
          console.log(err);
          setSuccessResponse(false);
          setStatusMsg(
            "Hubo un error de conexión.<br />Por favor intenta mas tarde."
          );
          setModalShow(true);
          //alert("hubo un error al suscribirte intenta mas tarde");
          setSendBtnEnable(true);
        });
    } catch (error) {
      console.log(error);
      setSuccessResponse(false);
      setStatusMsg(
        "Hubo un error de conexión.<br />Por favor intenta mas tarde."
      );
      setModalShow(true);
      //alert("hubo un error al suscribirte intenta mas tarde");
      setSendBtnEnable(true);
    }
  };
  const clearFields = () => {
    setName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setMessage("");
    setChecked(false);
    setIfClientBalanz(false);
  };

  let fields = [
    {
      name: "Nombre*",
      className: "contact-fields",
      setValue: setName,
      value: name,
      type: "text",
    },
    {
      name: "Apellido*",
      className: "contact-fields",
      setValue: setLastName,
      value: lastName,
      type: "text",
    },
    {
      name: "Email*",
      className: "contact-fields",
      setValue: setEmail,
      value: email,
      type: "text",
    },
    {
      name: "Teléfono*",
      className: "contact-fields",
      setValue: setPhone,
      value: phone,
      type: "text",
    },
    {
      name: "Mensaje*",
      className: "contact-textarea",
      setValue: setMessage,
      value: message,
      type: "textarea",
    },
    {
      name: "¿Sos cliente Balanz?",
      className: "contact-checkbox-cliente",
      setValue: setIfClientBalanz,
      value: ifclientBalanz,
      type: "radio",
      options: [
        {
          label: "Sí",
          value: "true",
        },
        {
          label: "No",
          value: "false",
        },
      ],
    },
    {
      name: "Acepto recibir información de Balanz",
      className: "contact-checkbox",
      setValue: setChecked,
      checked: checked,
      type: "checkbox",
    },
  ];
  let text = `Abrí tu cuenta especial (CERA) <br /><span class='highlightsbold'> y hacé que tu blanqueo rinda al máximo.</span>`;

  /* const AddressMap=()=>{
     return (
         <div className="google-map-code">
           <iframe src="https://www.google.com/maps/place/BALANZ/@-34.6033056,-58.3713899,18z/data=!4m5!3m4!1s0x0:0x86defadbfac6e7fa!8m2!3d-34.6032016!4d-58.3715851?authuser=1" width="800" height="600" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0" loading="lazy"></iframe>
         </div>
     );
   };*/

  useEffect(() => {
    const ulrParams = new URLSearchParams(window.location.search);
    const bam_campaign = ulrParams.get("bam_campaign") || "";
    if(bam_campaign !== "") setBamCampaign(bam_campaign);
    setUtmList(getUtmParamsString(window.location.search));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);
  const page = (
    <>
      <ProductBanner
        bannerContainerClassName="bannerInversiones"
        bannerTrasparecy="bannerMask"
        productTitle="Blanqueá fácil, invertí bien"
        bannerImage={Banner}
        bannerImageClassName="cedearsBannerImage"
        textContainerClassName="textContainer"
        bannerText={text}
        bannerTextClassName="bannerTextInversiones"
        bannerImageMobile={BannerMobile}
        mobile={isMobile}
      />
      <SalWrapper>
        <section className="fullwidth contacto-form">
          <Container>
            <Row className="g-0 d-flex justify-content-center">
              <Col xs={11} md={9} className="pb-0 pt-sm-5">
                <Row className="d-flex justify-content-center">
                  <Col xs={12} md={8}>
                    <h2 className="highlightsbold base-text text-left text-sm-center">
                    ¡Dejanos tus datos!
                    </h2>
                    <p className="base-text text-left text-sm-center highlightsbold pb-md-5 pb-3">
                    Un asesor te contactará a la brevedad para abrir tu cuenta CERA y ayudarte durante todo el proceso.
                    </p>
                  </Col>
                  <FormFields
                    fields={fields}
                    errorInputName={errorinputName}
                    errorInputLastName={errorinputLastName}
                    errorInputEmail={errorinputEmail}
                    errorInputPhone={errorinputPhone}
                    errorInputNote={errorInputNote}
                    errorInputChecked={errorinputChecked}
                    errorinputNameMessage={errorinputNameMessage}
                    errorinputLastNameMessage={errorinputLastNameMessage}
                    errorinputEmailMessage={errorinputEmailMessage}
                    errorinputPhoneMessage={errorinputPhoneMessage}
                    errorinputNoteMessage={errorinputNoteMessage}
                    errorinputCheckedMessage={errorinputCheckedMessage}
                    errorinputRadio={errorinputRadio}
                  ></FormFields>
                 
                </Row>
                <Row className="px-1 pt-2 align-items-center justify-content-sm-center">
                 
                  {sendBtnEnable ? (
                    <Button
                      variant="secondary btn-sm"
                      text="Enviar"
                      onClick={() => {
                        submit();
                      }}
                    />
                  ) : (
                    <Button
                      variant="disabled btn-sm"
                      text="Enviando..."
                      onClick={() => {
                        return false;
                      }}
                      disabled={"disabled"}
                    />
                  )}
                  
                </Row>
                <Row className="px-4 pt-4  align-items-center justify-content-sm-center">
                <p style={{ fontSize: "12px" }}>
                    El presente aviso no es una invitación a realizar inversión alguna. El destinatario deberá consultar a sus asesores legales, contables y/o impositivos sobre la conveniencia de abrir una cuenta especial de inversión a estos efectos. Para mayor información, contáctese con nuestros asesores. BALANZ CAPITAL VALORES S.A.U. ALyC N° 210 y ACDI N° 62 ante la CNV. INSCRIPTO IGJ - N° 13981 DEL L. 40, TOMO – SOCIEDAD POR ACCIONES CUIT: 30-71063067-0. BALANZ SOCIEDAD GERENTE DE FONDOS COMUNES DE INVERSIÓN S.A.U. AAPIC de FCI N° 32 ante la CNV. INSCRIPTO ANTE IGJ BAJO EL N° 12.486 DEL LIBRO 18 TOMO - DE SOCIEDADES POR ACCIONES CUIT: 33-70812346-9.
                </p>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

      </SalWrapper>
    </>
  );

  return (
    <main>
      <Layout
        title={pageTitle}
        description={pageDesc}
        childrem={page}
        category={"contacto"}
      ></Layout>
      <Modal
        className="formmodal"
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {successResponse ? "Muchas gracias" : "Atención"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: statusMsg }} />
        </Modal.Body>
      </Modal>
    </main>
  );
};

// Step 3: Export your component
export default ContactPage;
